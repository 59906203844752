const CDOH = (($) => {
  let $els = {};

  const init = () => {
    $els.container = $('#cdoh-showcase');
    $els.items = $('#cdoh-showcase .outer a');

    _buildDom();
  };

  const _buildDom = () => {
    let l = $els.items.length;

    $.each($els.items, (i, val) => {
      let $item = $(val);

      $item.css({
        left: (50 - 60 * Math.cos(-0.5 * Math.PI - 2 * (1 / l) * i * Math.PI)).toFixed(4) + '%',
        top: (50 + 60 * Math.sin(-0.5 * Math.PI - 2 * (1 / l) * i * Math.PI)).toFixed(4) + '%'
      });

      $item.on('mouseenter', (e) => {
        $(e.target).addClass('show');
      });

      $item.on('mouseleave', (e) => {
        $(e.target).removeClass('show');
      });
    });
  };

  module.exports = {
    init: init,
  };
})(jQuery);
