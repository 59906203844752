jQuery(document).ready(($) => {
  document.documentElement.classList.remove('no-js');
  initApp($);
});

const initApp = ($) => {
  let $body = $('body');
  // console.log(
  //   '%c App Initialized - Remove me when you get down to business!',
  //   'background-color: yellowgreen; color: white; display: block; line-height: 35px;',
  // );

  // GLOBAL
  // const MainMenu = require('./modules/global/mainMenu');
  // MainMenu.init();

  // PAGES
  if ($body.hasClass('home')) {
    const CDOH = require('./modules/cdohShowcase');
    CDOH.init();
  }
};
